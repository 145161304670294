import type { TypeSearchPromoBlockFields } from '@lib/generated-types'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import { getIconFromContentful } from '@lib/icons'
import { Card, Container, type ContainerProps, Icon, Typography } from '@shc/ui'
import ResponsiveImage from '@components/responsive-image'
import { AnalyticsLink } from '@components/analytics'
import { richTextRenderFactory } from '@lib/rich-text-options'
import Content from '@components/content'
import NextLink from 'next/link'

type SearchPromoBlockType = TypeSearchPromoBlockFields & ContainerProps

const SearchPromoBlock = ({
  variant,
  icon,
  image,
  title,
  summary,
  link,
  anchorOrExternalLink,
}: SearchPromoBlockType) => {
  const { route, isInternal } = routeMapper(link, anchorOrExternalLink)

  // variant case
  if (variant === 'inline') {
    return (
      <Container>
        <div className="flex md:gap-5 lg:gap-15 items-center justify-between py-8 border-t">
          <div className="flex flex-col gap-3 justify-between">
            <AnalyticsLink
              noUnderline
              href={route}
              target={isInternal ? undefined : '_blank'}
              rel={isInternal ? undefined : 'noopener noreferrer'}
              className="font-medium group">
              <Typography
                as="h2"
                variant="h4"
                className="text-primary-700 group-hover:underline font-bold">
                {title}
              </Typography>
            </AnalyticsLink>
            <Content className="text-sm">{richTextRenderFactory()(summary)}</Content>
          </div>
          {image && (
            <div className="flex flex-col justify-end items-center">
              {/* Link is present */}
              {image?.fields?.image && route && (
                <AnalyticsLink
                  as={NextLink}
                  href={route}
                  className="group rounded overflow-hidden block"
                  aria-label={title}>
                  <ResponsiveImage
                    image={{
                      altTag: image.fields.image.fields.title,
                      urlString: image.fields.image.fields?.file?.url,
                    }}
                    focalPoint="center"
                    columnWidthMax="100%"
                    className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded scale-100 duration-200 group-hover:scale-105 ease-in-out"
                  />
                </AnalyticsLink>
              )}

              {/* Link is NOT present */}
              {image?.fields?.image && !route && (
                <ResponsiveImage
                  image={{
                    altTag: image.fields.image.fields.title,
                    urlString: image.fields.image.fields?.file?.url,
                  }}
                  focalPoint="center"
                  columnWidthMax="100%"
                  className="md:w-[288px] md:h-[162px] lg:w-[335px] lg:h-[188px] rounded"
                />
              )}
            </div>
          )}
        </div>
      </Container>
    )
  }

  // default case (non-variant)
  return (
    <Container className="mb-8">
      <Card padding="sm" variant="outlined">
        <div className="flex flex-row">
          <div className="bg-primary-50 rounded-full size-12 min-h-12 min-w-12 mr-4 flex items-center justify-center">
            {icon && (
              <Icon fixedWidth icon={getIconFromContentful(icon)} size="lg" color="#18808C" />
            )}
          </div>
          <div className="flex flex-col gap-1">
            <Typography variant="small-body-semibold">{title}</Typography>
            <Content className="text-sm">{richTextRenderFactory()(summary)}</Content>
          </div>
        </div>
      </Card>
    </Container>
  )
}

export default SearchPromoBlock

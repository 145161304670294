'use client'

import { Container, Icon, Typography } from '@shc/ui'
import { searchInputDebounceMs } from '@lib/constants'
import TextField from '@components/text-field'
import { useState, type ChangeEvent, type ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { useClearRefinements, useSearchBox } from 'react-instantsearch'
import { debounceQueryHook } from '@lib/search'
import SearchLocationAutocomplete from '@app/[locale]/(main)/search/_components/search-location-autocomplete'
import { useSearchFacetStore } from '@app/[locale]/(main)/search/_store/search-facet-store'

export interface SearchHeroProps extends ComponentPropsWithoutRef<'div'> {
  title?: string
  searchPlaceholderText: string
  disableLocationSearch?: boolean
}

const queryHook = debounceQueryHook(searchInputDebounceMs)

const SearchHero = ({
  title,
  searchPlaceholderText,
  disableLocationSearch = false,
}: SearchHeroProps) => {
  const clearAllFacets = useSearchFacetStore((state) => state.clearAllFacets)
  const { refine } = useClearRefinements()

  const searchBox = useSearchBox({ queryHook })
  const [queryValue, setQueryValue] = useState(searchBox.query)

  /* Handler */
  const handleQueryValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const q = event.target.value
    setQueryValue(q)
    searchBox.refine(q)
    if (q === '') {
      refine()
      clearAllFacets()
    }
  }

  return (
    <div className="bg-primary-50">
      <Container
        className={clsx(
          'relative',
          'grid grid-cols-12 col-span-12 xl:col-span-10',
          'py-6 md:py-8 lg:py-15 gap-4 md:gap-8'
        )}>
        <div className="col-span-12 xl:col-span-8">
          <Typography variant="h1">{title}</Typography>
        </div>
        {/* search form fields */}
        <div className="col-span-12 md:col-span-6 xl:col-span-6">
          <TextField
            type="search"
            value={queryValue}
            onChange={handleQueryValueChange}
            autoFocus={true}
            autoComplete="off"
            autoCorrect="off"
            spellCheck={false}
            name="q"
            label="Search term"
            hideLabel={true}
            startAdornment={<Icon className="h-3.5" icon="magnifying-glass" />}
            placeholder={searchPlaceholderText}
          />
        </div>

        <div className={clsx('col-span-12 md:col-span-6 xl:col-span-4')}>
          <SearchLocationAutocomplete disabled={disableLocationSearch} />
        </div>
      </Container>
    </div>
  )
}
export default SearchHero
